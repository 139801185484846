<template>
  <div class="modal fade" id="add-agency-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-apply-promoLabel">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" v-if="!agency">
            <label for="page">User</label>
            <select :class="['form-select', {'is-invalid': errorFor('user')}]"
                    :disabled="loading"
                    class="form-control"
                    v-model="form.user">
              <option value="">Select User</option>
              <option :value="user.id"
                      v-for="user in users"
                      :key="'user_'+user.id">{{ user.name }}</option>
            </select>
            <v-errors :errors="errorFor('user')" />
          </div>
          <div class="form-group">
            <label for="page">Name</label>
            <input type="text"
                   :disabled="loading"
                   v-model="form.name"
                   :class="['form-control', {'is-invalid': errorFor('name')}]">
            <v-errors :errors="errorFor('name')" />
          </div>
          <div class="form-group">
            <label for="page">Full License Price</label>
            <input type="text"
                   v-model="form.full_price"
                   :disabled="loading"
                   :class="['form-control', {'is-invalid': errorFor('full_price')}]">
            <v-errors :errors="errorFor('full_price')" />
          </div>
          <div class="form-group">
            <label for="page">Collab License Price</label>
            <input type="text"
                   v-model="form.influencer_price"
                   :disabled="loading"
                   :class="['form-control', {'is-invalid': errorFor('influencer_price')}]">
            <v-errors :errors="errorFor('influencer_price')" />
          </div>
          <div class="form-group">
            <label for="page">Upgrade Price</label>
            <select :class="['form-control', {'is-invalid': errorFor('upgrade_price_id')}]"
                    v-model="form.upgrade_price_id">
              <option value="">Select Price</option>
              <option :value="price.id"
                      v-for="price in upgradePrices"
                      :key="'price_'+price.id">${{ price.unit_amount + ' / ' + price.interval }}</option>
            </select>
            <v-errors :errors="errorFor('upgrade_price_id')" />
          </div>
          <div class="form-group" v-if="!agency">
            <label for="page">Trial (days)</label>
            <input type="text"
                   v-model="form.trial_days"
                   :disabled="loading"
                   :class="['form-control', {'is-invalid': errorFor('trial_days')}]">
            <v-errors :errors="errorFor('trial_days')" />
          </div>
          <hr>
          <div class="form-group">
            <div class="custom-control custom-checkbox checkbox-primary pl-0">
              <input type="checkbox"
                     v-model="form.aweber"
                     class="custom-control-input"
                     id="basicChk2">
              <label class="custom-control-label" for="basicChk2">Aweber</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success btn-sm"
                  :disabled="loading"
                  @click="submit">{{ agency ? 'Save' : 'Send Invitation' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      users: [],
      agency: null,
      upgradePrices: [],
      form: {
        user: '',
        name: '',
        upgrade_price_id: '',
        full_price: '',
        influencer_price: '',
        trial_days: '',
        aweber: false
      }
    }
  },
  created() {
    this.loadUsers();
    this.loadUpgradePrices();
  },
  computed: {
    title() {
      if (this.agency)
        return 'Edit Agency';
      else
        return 'Add Agency';
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-agency-modal'))
  },
  methods: {
    resetForm() {
      this.form = {
        user: '',
        name: '',
        upgrade_price_id: '',
        full_price: '',
        influencer_price: '',
        trial_days: '',
        aweber: false
      };
    },
    show(agency = null) {
      this.agency = agency;
      this.resetForm();

      if (agency) {
        this.form = {...agency}
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadUsers() {
      this.users = [];

      this.axios.get('/users')
        .then(res => {
          this.users = res.data.data;
        })
    },
    loadUpgradePrices() {
      this.upgradePrices = [];

      this.axios.get('/upgrade-prices')
        .then(res => {
          this.upgradePrices = res.data.data;
        })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.agency) {
        this.axios.patch('/agencies/' + this.agency.id, this.form)
          .then(() => {
            this.hide();
            this.$emit('added')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.axios.post('/agencies', this.form)
          .then(() => {
            this.hide();
            this.$emit('added')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      }


    },
  }
}
</script>